import { Elm } from "./Main.elm"

// HELPERS
JSON.tryParse = function (value) {
    try {
        return JSON.parse(value);
    } catch (error) {
        console.log(`JSON.tryParse error: ${error}`);
        return null;
    }
};


function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            e.name === "QuotaExceededError" &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};
// END HELPERS

const APP_NAME = "HUMAN_SKELETON_1";
const SETTINGS_HINTTIME = `${APP_NAME}.userSettings.hintTime`;

var app = Elm.Main.init(
    {
        node: document.getElementById("root")
        , flags: {
            hintTime:
                storageAvailable("localStorage") ? JSON.tryParse(localStorage.getItem(SETTINGS_HINTTIME)) : null
        }
    }
);

app.ports.persistSettings.subscribe(function (settings) {
    if (!storageAvailable("localStorage"))
        return;

    localStorage.setItem(SETTINGS_HINTTIME, JSON.stringify(settings.hintTime ?? null));
});


