port module Main exposing (..)

import Browser
import Browser.Dom as Dom
import Browser.Events
import Common exposing (Guessable, GuessableChar(..), GuessableStatus(..), GuessableString, TextBreak(..), mostFound, toGuessableString)
import Data.HumanSkeleton as HumanSkeleton
import Helpers.Geometry exposing (..)
import Helpers.Keyboard as Keyboard
import Helpers.Palette as Palette
import Helpers.String as String
import Helpers.Style as Style
import Html as H exposing (Html)
import Html.Attributes as HA
import Html.Events as Ev
import Html.Extra as H
import Json.Decode as D
import Json.Encode as E
import Maybe.Extra as Maybe
import Page.HumanSkeleton as HumanSkeleton
import Random
import String.Extra as String
import Task
import Time exposing (Posix)



-- PORTS


port persistSettings : E.Value -> Cmd msg


encodeSettings : Settings -> E.Value
encodeSettings settings =
    E.object
        [ ( "hintTime", E.list E.int [ Tuple.first settings.hintTime, Tuple.second settings.hintTime ] ) ]



-- MAIN


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }


type alias Flags =
    { hintTime : Maybe ( Int, Int ) }


defaults =
    { settings =
        { hintTime = ( 10, 5 )
        , hintTimeRange = ( 1, 10 )
        }
    , ui =
        { buttonFontSize = 18 }
    }


tickResolution : number
tickResolution =
    100


scaleFont : Dom.Viewport -> Int -> Int
scaleFont { viewport } size =
    let
        ( w, h ) =
            HumanSkeleton.dimensions

        scaleFactor =
            min
                (viewport.width / toFloat w)
                (viewport.height / toFloat h)
    in
    toFloat size * scaleFactor |> floor



-- MODEL


type alias Model =
    { guessables : List (Guessable (HumanSkeleton.Bone Msg))
    , appMode : Mode
    , settings : Settings
    , hovered : Maybe (Guessable (HumanSkeleton.Bone Msg))
    , appStatus : AppStatus
    , isPaused : Bool
    }


type AppStatus
    = Loading
    | Running { viewport : Dom.Viewport }


type Mode
    = BrowseMode
    | OptionsMode OptionsModeData
    | PlayMode PlayModeData


type alias Settings =
    { hintTime : ( Int, Int ) }


type alias OptionsModeData =
    { hintTimeFirst : Maybe Int
    , hintTimeSubsequent : Maybe Int
    , invalidSetting : InvalidSetting
    }


type InvalidSetting
    = AllValidSettings
    | HintTimeOutOfBounds


type alias PlayModeData =
    { maybeCurrentGuess : Maybe String
    , maybeActiveGuessable : Maybe (HumanSkeleton.Bone Msg)
    , hintTimeSettings : ( Int, Int )
    , ticksNextHint : Int
    , currentGuessableAnswer : List GuessableChar
    , revealedGuessableAnswers : List GuessableString
    , maybeWordResetAt : Maybe Posix
    , phase : PlayPhase
    }


type PlayPhase
    = PreparationPhase
    | RunningPhase
    | FinishedPhase


type SettingValue
    = HintTimeFirstSetting
    | HintTimeSubsequentSetting


init : Flags -> ( Model, Cmd Msg )
init ({ hintTime } as flags) =
    let
        model =
            { guessables = List.map (\p -> { item = p, status = AllRevealed }) HumanSkeleton.bones
            , appMode = BrowseMode
            , settings =
                { hintTime =
                    case hintTime of
                        Nothing ->
                            defaults.settings.hintTime

                        Just ht ->
                            ht
                }
            , hovered = Nothing
            , appStatus = Loading
            , isPaused = False
            }
    in
    ( model
    , Dom.getViewport |> Task.perform GotViewport
    )


reset : Model -> Model
reset model =
    { model
        | guessables = List.map (\p -> { item = p, status = AllRevealed }) HumanSkeleton.bones
        , appMode = BrowseMode
        , hovered = Nothing
    }


initPlayMode : Settings -> Mode
initPlayMode { hintTime } =
    PlayMode
        { maybeCurrentGuess = Nothing
        , maybeActiveGuessable = Nothing
        , hintTimeSettings = hintTime
        , ticksNextHint = 0
        , currentGuessableAnswer = []
        , revealedGuessableAnswers = []
        , maybeWordResetAt = Nothing
        , phase = PreparationPhase
        }


humanSkeletonConfig : HumanSkeleton.EventsConfig Msg
humanSkeletonConfig =
    { onMouseOver = OnHover
    , onMouseOut = OnMouseOut
    , onClickSurface = OnClickSurface
    }



-- UPDATE


type Msg
    = NoOp
    | GotViewport Dom.Viewport
    | ToggleOptions
    | UpdateSetting SettingValue String
    | OpenQuiz
    | QuitPlay
    | OnHover (Guessable (HumanSkeleton.Bone Msg))
    | OnMouseOut
    | CommandRequest Command
    | OnKeyDown String
    | OnClickSurface
    | Guess String
    | PickedRandomConcept (HumanSkeleton.Bone Msg)
    | WindowResized Int Int
    | VisibilityChanged Browser.Events.Visibility
    | Tick Posix


type Command
    = PickRandom


pickRandomConcept : Model -> ( Model, Cmd Msg )
pickRandomConcept ({ guessables, appMode } as origin) =
    case
        guessables
            |> List.filter (\c -> c.status == Inactive)
            |> List.map (\c -> c.item)
    of
        [] ->
            case appMode of
                PlayMode playModeData ->
                    ( { origin
                        | appMode =
                            PlayMode
                                { playModeData
                                    | phase = FinishedPhase
                                }
                      }
                    , Cmd.none
                    )

                _ ->
                    ( origin, Cmd.none )

        h :: t ->
            ( origin
            , Random.generate PickedRandomConcept (Random.uniform h t)
            )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg ({ appMode, settings } as model) =
    let
        noChange =
            ( model, Cmd.none )
    in
    case msg of
        NoOp ->
            noChange

        GotViewport viewport ->
            ( { model | appStatus = Running { viewport = viewport } }
            , Cmd.none
            )

        WindowResized _ _ ->
            ( model
            , Dom.getViewport |> Task.perform GotViewport
            )

        Tick posix ->
            case appMode of
                PlayMode ({ ticksNextHint, maybeWordResetAt, maybeCurrentGuess, currentGuessableAnswer, hintTimeSettings } as playModeData) ->
                    let
                        ( firstHintTime, subsequentHintTime ) =
                            ( Tuple.first hintTimeSettings * (1000 // tickResolution)
                            , Tuple.second hintTimeSettings * (1000 // tickResolution)
                            )

                        hintTime =
                            if Common.isAllHidden currentGuessableAnswer then
                                firstHintTime

                            else
                                subsequentHintTime

                        ( ticksNextHint_, isHintTriggered ) =
                            if 1 + ticksNextHint >= hintTime then
                                if Common.isRevealed currentGuessableAnswer then
                                    ( 0, False )

                                else
                                    ( 0, True )

                            else
                                ( 1 + ticksNextHint, False )

                        currentGuessableAnswer_ =
                            if isHintTriggered then
                                Common.hintNext currentGuessableAnswer

                            else
                                currentGuessableAnswer

                        maybeCurrentGuess_ =
                            if isHintTriggered then
                                Common.getFoundString currentGuessableAnswer_
                                    |> Just

                            else
                                maybeCurrentGuess

                        maybeWordResetAt_ =
                            case maybeWordResetAt of
                                Nothing ->
                                    case maybeCurrentGuess of
                                        Nothing ->
                                            Nothing

                                        Just currentGuess ->
                                            if isHintTriggered && (not <| String.compares currentGuess (Common.getFoundString currentGuessableAnswer)) then
                                                Just posix

                                            else
                                                Nothing

                                Just wordResetAt ->
                                    if (Time.posixToMillis posix - Time.posixToMillis wordResetAt) > 500 then
                                        Nothing

                                    else
                                        maybeWordResetAt
                    in
                    ( { model
                        | appMode =
                            PlayMode
                                { playModeData
                                    | ticksNextHint = ticksNextHint_
                                    , currentGuessableAnswer = currentGuessableAnswer_
                                    , maybeCurrentGuess =
                                        maybeCurrentGuess_
                                    , maybeWordResetAt = maybeWordResetAt_
                                }
                      }
                    , Cmd.none
                    )

                _ ->
                    noChange

        CommandRequest command ->
            case command of
                PickRandom ->
                    noChange

        ToggleOptions ->
            case appMode of
                BrowseMode ->
                    ( { model
                        | appMode =
                            OptionsMode
                                { hintTimeFirst = Tuple.first settings.hintTime |> Just
                                , hintTimeSubsequent = Tuple.second settings.hintTime |> Just
                                , invalidSetting = AllValidSettings
                                }
                      }
                    , Cmd.none
                    )

                OptionsMode { hintTimeFirst, hintTimeSubsequent } ->
                    let
                        settings_ =
                            case ( hintTimeFirst, hintTimeSubsequent ) of
                                ( Just n1, Just n2 ) ->
                                    { hintTime = ( n1, n2 ) }

                                _ ->
                                    settings
                    in
                    ( { model
                        | appMode = BrowseMode
                        , settings = settings_
                      }
                    , persistSettings (encodeSettings settings_)
                    )

                _ ->
                    noChange

        UpdateSetting setting val ->
            case appMode of
                OptionsMode optionsData ->
                    let
                        settingVal =
                            String.toInt val
                                |> Maybe.map (clamp (Tuple.first defaults.settings.hintTimeRange) (Tuple.second defaults.settings.hintTimeRange))

                        invalidSetting_ =
                            case String.toInt val of
                                Nothing ->
                                    AllValidSettings

                                Just n ->
                                    if 1 <= n && n <= 10 then
                                        AllValidSettings

                                    else
                                        HintTimeOutOfBounds

                        optionsData_ =
                            case setting of
                                HintTimeFirstSetting ->
                                    { optionsData
                                        | hintTimeFirst = settingVal
                                        , invalidSetting = invalidSetting_
                                    }

                                HintTimeSubsequentSetting ->
                                    { optionsData
                                        | hintTimeSubsequent = settingVal
                                        , invalidSetting = invalidSetting_
                                    }
                    in
                    ( { model | appMode = OptionsMode optionsData_ }
                    , Cmd.none
                    )

                _ ->
                    noChange

        OpenQuiz ->
            pickRandomConcept
                { model
                    | guessables = model.guessables |> List.map (\c -> { c | status = Inactive })
                    , appMode = initPlayMode settings
                }

        QuitPlay ->
            ( reset model
            , Cmd.none
            )

        OnHover guessable ->
            ( { model
                | hovered = Just guessable
              }
            , Cmd.none
            )

        OnMouseOut ->
            ( { model
                | hovered = Nothing
              }
            , Cmd.none
            )

        PickedRandomConcept item ->
            case appMode of
                PlayMode playModeData ->
                    ( { model
                        | guessables =
                            List.map
                                (\c ->
                                    if item == c.item then
                                        { c | status = Active }

                                    else
                                        c
                                )
                                model.guessables
                        , appMode =
                            PlayMode
                                { playModeData
                                    | maybeActiveGuessable = Just item
                                    , ticksNextHint = 0
                                    , maybeCurrentGuess = Just ""
                                    , currentGuessableAnswer =
                                        case item.textBreak of
                                            NoTextBreak ->
                                                toGuessableString item.name

                                            NewLineTextBreak ->
                                                toGuessableString item.name
                                                    |> Common.rightRevealGuessableString
                                                        (item.name |> String.split " " |> List.reverse |> List.head |> Maybe.map String.length |> Maybe.withDefault 0)
                                }
                      }
                    , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                    )

                _ ->
                    noChange

        OnKeyDown keycode ->
            let
                key =
                    Keyboard.toKey keycode
            in
            case appMode of
                PlayMode ({ maybeCurrentGuess, phase } as playModeData) ->
                    case phase of
                        FinishedPhase ->
                            noChange

                        _ ->
                            case maybeCurrentGuess of
                                Nothing ->
                                    case key of
                                        Keyboard.Character char ->
                                            ( { model
                                                | appMode =
                                                    PlayMode
                                                        { playModeData
                                                            | maybeCurrentGuess =
                                                                if char == ' ' then
                                                                    Just String.empty

                                                                else
                                                                    Just (String.fromChar char)
                                                            , currentGuessableAnswer =
                                                                mostFound
                                                                    playModeData.currentGuessableAnswer
                                                                    (Common.matchLeftGuessableString (String.fromChar char) playModeData.currentGuessableAnswer)
                                                            , phase = RunningPhase
                                                        }
                                              }
                                            , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                                            )

                                        _ ->
                                            ( { model
                                                | appMode =
                                                    PlayMode
                                                        { playModeData
                                                            | maybeCurrentGuess = Just String.empty
                                                            , phase = RunningPhase
                                                        }
                                              }
                                            , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                                            )

                                Just _ ->
                                    case key of
                                        Keyboard.Control Keyboard.Escape ->
                                            ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Nothing } }
                                            , Cmd.none
                                            )

                                        _ ->
                                            noChange

                _ ->
                    noChange

        OnClickSurface ->
            case appMode of
                PlayMode ({ maybeCurrentGuess, phase } as playModeData) ->
                    case phase of
                        PreparationPhase ->
                            ( { model
                                | appMode =
                                    PlayMode
                                        { playModeData
                                            | maybeCurrentGuess = Just String.empty
                                            , phase = RunningPhase
                                        }
                              }
                            , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                            )

                        RunningPhase ->
                            case maybeCurrentGuess of
                                Nothing ->
                                    ( { model
                                        | appMode =
                                            PlayMode
                                                { playModeData
                                                    | maybeCurrentGuess = Just String.empty
                                                    , phase = RunningPhase
                                                }
                                      }
                                    , Dom.focus "guess-input" |> Task.attempt (\_ -> NoOp)
                                    )

                                Just _ ->
                                    ( { model | appMode = PlayMode { playModeData | maybeCurrentGuess = Nothing } }
                                    , Cmd.none
                                    )

                        FinishedPhase ->
                            noChange

                _ ->
                    noChange

        VisibilityChanged visibility ->
            case visibility of
                Browser.Events.Hidden ->
                    ( { model | isPaused = True }
                    , Cmd.none
                    )

                Browser.Events.Visible ->
                    ( { model | isPaused = False }
                    , Cmd.none
                    )

        Guess guess ->
            triggerGuess model guess


triggerGuess : Model -> String -> ( Model, Cmd Msg )
triggerGuess ({ appMode } as model) guess =
    case appMode of
        PlayMode playModeData ->
            let
                currentGuessableAnswer_ =
                    mostFound
                        playModeData.currentGuessableAnswer
                        (Common.matchLeftGuessableString guess playModeData.currentGuessableAnswer)

                guessables_ =
                    if Common.isFound currentGuessableAnswer_ then
                        List.map
                            (\g ->
                                if g.status == Active then
                                    { g | status = Revealed currentGuessableAnswer_ }

                                else
                                    g
                            )
                            model.guessables

                    else
                        model.guessables

                currentGuess =
                    if Common.isLeftFound guess currentGuessableAnswer_ then
                        if String.length guess > Common.lengthGuessable currentGuessableAnswer_ then
                            playModeData.maybeCurrentGuess

                        else
                            Just guess

                    else
                        Common.getFoundString currentGuessableAnswer_ |> Just
            in
            if guessables_ == model.guessables then
                let
                    ticksNextHint_ =
                        if currentGuessableAnswer_ /= playModeData.currentGuessableAnswer then
                            0

                        else
                            playModeData.ticksNextHint
                in
                ( { model
                    | appMode =
                        PlayMode
                            { playModeData
                                | maybeCurrentGuess = currentGuess
                                , ticksNextHint = ticksNextHint_
                                , currentGuessableAnswer = currentGuessableAnswer_
                            }
                  }
                , Cmd.none
                )

            else
                pickRandomConcept
                    { model
                        | guessables = guessables_
                        , appMode =
                            PlayMode
                                { playModeData
                                    | maybeCurrentGuess = Nothing
                                    , ticksNextHint = 0
                                    , revealedGuessableAnswers = currentGuessableAnswer_ :: playModeData.revealedGuessableAnswers
                                }
                    }

        _ ->
            ( model, Cmd.none )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions { appMode, isPaused } =
    let
        modeSubscriptions =
            case appMode of
                PlayMode { phase } ->
                    case phase of
                        RunningPhase ->
                            if not isPaused then
                                [ Time.every tickResolution Tick ]

                            else
                                []

                        _ ->
                            []

                _ ->
                    []
    in
    Sub.batch
        ([ Browser.Events.onResize WindowResized
         , Browser.Events.onKeyDown (D.map OnKeyDown (D.field "key" D.string))
         , Browser.Events.onVisibilityChange VisibilityChanged
         ]
            ++ modeSubscriptions
        )



-- VIEW


navigationButtonPosition : Dom.Viewport -> OrdinalDirection -> List (H.Attribute msg)
navigationButtonPosition viewport direction =
    let
        defaultPadding =
            15

        placement : Int -> List (H.Attribute msg)
        placement padding =
            case direction of
                NorthEast ->
                    [ HA.style "top" (String.px padding)
                    , HA.style "right" (String.px padding)
                    ]

                SouthEast ->
                    [ HA.style "bottom" (String.px padding)
                    , HA.style "right" (String.px padding)
                    ]

                SouthWest ->
                    [ HA.style "bottom" (String.px padding)
                    , HA.style "left" (String.px padding)
                    ]

                NorthWest ->
                    [ HA.style "top" (String.px padding)
                    , HA.style "left" (String.px padding)
                    ]
    in
    HA.style "position" "fixed" :: placement defaultPadding


menuFontSize : Dom.Viewport -> String
menuFontSize viewport =
    String.px <|
        max
            (scaleFont viewport defaults.ui.buttonFontSize)
            14


viewPlayTrigger : Dom.Viewport -> Html Msg
viewPlayTrigger viewport =
    H.button
        ([ Ev.onClick OpenQuiz
         , HA.style "font-size" <|
            menuFontSize viewport
         , HA.class "font-mono"
         ]
            ++ navigationButtonPosition viewport NorthWest
        )
        [ H.text "Start Quiz" ]


viewOptionsTrigger : Dom.Viewport -> Mode -> Html Msg
viewOptionsTrigger viewport appMode =
    H.button
        ([ Ev.onClick ToggleOptions
         , HA.style "font-size" <|
            menuFontSize viewport
         , HA.class "font-mono"
         ]
            ++ navigationButtonPosition viewport NorthEast
        )
        [ H.text <|
            case appMode of
                BrowseMode ->
                    "Options & Info"

                OptionsMode _ ->
                    "Close"

                _ ->
                    ""
        ]


viewQuitPlayTrigger : Dom.Viewport -> Html Msg
viewQuitPlayTrigger viewport =
    H.button
        ([ Ev.onClick QuitPlay
         , HA.style "font-size" <|
            menuFontSize viewport
         , HA.class "font-mono"
         ]
            ++ navigationButtonPosition viewport NorthWest
        )
        [ H.text "Quit Quiz" ]


viewGuessInput : Dom.Viewport -> HumanSkeleton.Bone msg -> Common.GuessableString -> String -> Bool -> Html Msg
viewGuessInput ({ viewport } as domViewport) activeGuessable guessableAnswer currentGuess isWordReset =
    let
        fontSize =
            --floor viewport.height // 14
            --floor viewport.width // 14
            scaleFont domViewport 55

        fontScaleFactor =
            toFloat fontSize
                / 1.65
                |> floor

        horizontalPadding =
            (totalWidth // 2) - ((fontScaleFactor * String.length (Common.excludeRevealed guessableAnswer |> Common.fromGuessableString)) // 2)

        horizontalPaddingRevealed =
            (totalWidth // 2) - ((fontScaleFactor * String.length (Common.getRevealed guessableAnswer)) // 2)

        verticalPadding =
            fontSize // 2

        totalWidth =
            floor viewport.width

        isWrongGuess : Bool
        isWrongGuess =
            String.length currentGuess == String.length activeGuessable.name && (not <| String.compares currentGuess activeGuessable.name)

        revealedColor =
            "white"

        hintColor =
            "#ccc"

        isMultiline =
            (guessableAnswer |> Common.getRevealed |> String.length) > 0
    in
    H.div
        [ HA.style "position" "absolute"
        , HA.style "width" "100%"

        --, HA.style "top" "50%"
        --, HA.style "transform" "translateY(-50%)"
        , HA.style "bottom" "0"
        , HA.style "overflow" "hidden"
        , if isWrongGuess then
            HA.class "animate-background-grey-red"
            --else if isWordReset then
            -- HA.class "animate-background-grey-red-short"

          else
            HA.style "background-color" "rgba(34,34,34,0.5)"
        ]
        [ H.input
            [ HA.id "guess-hints"
            , HA.autocomplete False
            , HA.spellcheck False
            , HA.value <|
                String.spaceover
                    (String.length currentGuess)
                    (List.map
                        (\gc ->
                            case gc of
                                FoundChar _ ->
                                    String.space

                                HintedChar c _ ->
                                    c

                                HiddenChar c ->
                                    String.scrambleCharAlt c

                                RevealedChar c ->
                                    c

                                SpaceChar ->
                                    String.space
                        )
                        (Common.excludeRevealed guessableAnswer)
                        |> String.fromList
                    )
            , HA.style "outline" "0"
            , HA.style "border" "0"
            , HA.style "margin" "0"
            , HA.style "color" hintColor
            , Style.transparentBackground
            , HA.style "padding" <|
                if not isMultiline then
                    String.px verticalPadding ++ " " ++ String.px horizontalPadding

                else
                    String.px (verticalPadding // 2) ++ " " ++ String.px horizontalPadding ++ " 0"
            , HA.style "font-size" (String.px fontSize)
            , HA.class "font-mono"
            ]
            []
        , H.input
            [ HA.id "guess-input"
            , HA.autocomplete False
            , HA.spellcheck False
            , HA.value currentGuess
            , HA.style "position" "absolute"
            , HA.style "width" "100%"
            , HA.style "top" "0"
            , HA.style "left" "0"
            , HA.style "outline" "0"
            , HA.style "border" "0"
            , HA.style "margin" "0"
            , Style.transparentBackground
            , HA.style "padding" <|
                if not isMultiline then
                    String.px verticalPadding ++ " " ++ String.px horizontalPadding

                else
                    String.px (verticalPadding // 2) ++ " " ++ String.px horizontalPadding ++ " 0"
            , HA.style "font-size" (String.px fontSize)
            , HA.style "color" revealedColor
            , HA.class "font-mono"
            , Ev.onInput Guess
            ]
            []
        , if 0 == (String.length <| Common.getRevealed guessableAnswer) then
            H.nothing

          else
            H.input
                [ HA.id "guess-revealed"
                , HA.autocomplete False
                , HA.spellcheck False
                , HA.value <|
                    Common.getRevealed guessableAnswer
                , HA.style "outline" "0"
                , HA.style "border" "0"
                , HA.style "color" revealedColor
                , Style.transparentBackground
                , HA.style "padding" <|
                    "0 "
                        ++ String.px horizontalPaddingRevealed
                        ++ " "
                        ++ String.px (verticalPadding // 2)
                , HA.style "font-size" (String.px fontSize)
                , HA.class "font-mono"
                ]
                []
        ]


view : Model -> Html Msg
view { guessables, appMode, hovered, appStatus, settings } =
    case appStatus of
        Loading ->
            H.nothing

        Running { viewport } ->
            case appMode of
                BrowseMode ->
                    H.div
                        []
                        [ HumanSkeleton.view viewport
                            { bones = guessables
                            , originalDimensions = HumanSkeleton.dimensions
                            , hovered = hovered
                            , showRevealedHintedOnly = False
                            , config = humanSkeletonConfig
                            , maybeFocused = Nothing
                            }
                        , viewPlayTrigger viewport
                        , viewOptionsTrigger viewport appMode
                        ]

                OptionsMode { hintTimeFirst, hintTimeSubsequent, invalidSetting } ->
                    H.div
                        [ HA.style "display" "flex"
                        , HA.style "flex-direction" "column"
                        , HA.style "padding" "60px 5%"
                        , HA.style "font-size" "16px"
                        , HA.style "height" "100%"
                        , HA.style "background-color" Palette.backgroundColor
                        ]
                        [ H.span [] [ H.text "Options" ]
                        , H.hr
                            [ HA.style "margin-bottom" "20px" ]
                            []
                        , H.span []
                            [ H.text "Time before showing the next letter as a hint "
                            , H.span
                                [ case invalidSetting of
                                    HintTimeOutOfBounds ->
                                        HA.class "animate-color-red"

                                    _ ->
                                        HA.class ""
                                ]
                                [ H.text "(1-10)" ]
                            , H.text ":"
                            ]
                        , H.div
                            [ HA.style "padding" "10px 15px"
                            , HA.style "gap" "10px"
                            , HA.style "display" "flex"
                            , HA.style "flex-direction" "column"
                            ]
                            [ H.div
                                [ HA.style "flex-direction" "row"
                                , HA.style "display" "flex"
                                , HA.style "gap" "10px"
                                ]
                                [ H.input
                                    [ HA.class "number-input"
                                    , HA.style "font-size" "20px"
                                    , HA.style "width" "40px"
                                    , HA.style "height" "40px"
                                    , HA.style "text-align" "center"
                                    , HA.type_ "number"
                                    , HA.value <|
                                        case hintTimeFirst |> Maybe.map String.fromInt of
                                            Nothing ->
                                                ""

                                            Just n ->
                                                n
                                    , Ev.onInput (UpdateSetting HintTimeFirstSetting)
                                    ]
                                    []
                                , H.span
                                    [ HA.style "flex" "1"
                                    , HA.style "align-content" "center"
                                    ]
                                    [ H.text "seconds for the first letter" ]
                                ]
                            , H.div
                                [ HA.style "flex-direction" "row"
                                , HA.style "display" "flex"
                                , HA.style "gap" "10px"
                                ]
                                [ H.input
                                    [ HA.class "number-input"
                                    , HA.style "font-size" "20px"
                                    , HA.style "width" "40px"
                                    , HA.style "height" "40px"
                                    , HA.style "text-align" "center"
                                    , HA.type_ "number"
                                    , HA.value <|
                                        case hintTimeSubsequent |> Maybe.map String.fromInt of
                                            Nothing ->
                                                ""

                                            Just n ->
                                                n
                                    , Ev.onInput (UpdateSetting HintTimeSubsequentSetting)
                                    ]
                                    []
                                , H.span
                                    [ HA.style "flex" "1"
                                    , HA.style "align-content" "center"
                                    ]
                                    [ H.text "seconds for each of the subsequent" ]
                                ]
                            ]
                        , H.span [ HA.style "margin-top" "40px" ] [ H.text "General information" ]
                        , H.hr
                            [ HA.style "margin-bottom" "20px" ]
                            []
                        , H.span
                            []
                            [ H.text "This simple app aims to teach you the names of the major bones of the human body."
                            , H.p []
                                [ H.text "The next letter for the bone you're currently trying to name will be shown after the time in the settings above has run out." ]
                            , H.p []
                                [ H.text <| "Can you make it through without any hints? " ++ String.fromChar (Char.fromCode 128526) ]
                            ]
                        , H.span [ HA.style "margin-top" "40px" ] [ H.text "Credits" ]
                        , H.hr
                            [ HA.style "margin-bottom" "20px" ]
                            []
                        , H.span
                            []
                            [ H.text "Original skeleton image by "
                            , H.a
                                [ HA.href "https://commons.wikimedia.org/wiki/User:LadyofHats"
                                , HA.target "_blank"
                                ]
                                [ H.text "LadyOfHats" ]
                            , H.text "."
                            ]
                        , viewOptionsTrigger viewport appMode
                        ]

                PlayMode ({ phase, maybeWordResetAt, revealedGuessableAnswers, maybeCurrentGuess, maybeActiveGuessable, currentGuessableAnswer } as playModeData) ->
                    case phase of
                        PreparationPhase ->
                            H.div
                                [ HA.style "width" "100%"
                                , HA.style "height" "100%"
                                ]
                                [ HumanSkeleton.view viewport
                                    { bones = guessables
                                    , originalDimensions = HumanSkeleton.dimensions
                                    , hovered = Nothing
                                    , showRevealedHintedOnly = False
                                    , config = humanSkeletonConfig
                                    , maybeFocused = Nothing
                                    }
                                , viewQuitPlayTrigger viewport
                                , H.div
                                    [ HA.style "width" "100%"
                                    , HA.style "height" "100%"
                                    , HA.style "position" "absolute"
                                    , HA.style "top" "0"
                                    , HA.style "left" "0"
                                    , HA.style "display" "flex"
                                    , HA.style "justify-content" "center"
                                    , HA.style "align-items" "center"
                                    , HA.style "font-size" <| String.px (scaleFont viewport 60)
                                    , HA.style "font-weight" "bold"
                                    , HA.style "color" "rgb(148 163 184)"
                                    , HA.style "pointer-events" "none"
                                    ]
                                    [ H.div
                                        [ HA.style "padding" "40px"
                                        , HA.style "text-align" "center"
                                        , HA.style "opacity" "0"
                                        , HA.class "animate-pulse-color"
                                        ]
                                        [ H.text "Touch screen to start" ]
                                    ]
                                ]

                        FinishedPhase ->
                            let
                                infoHeight =
                                    70

                                hintsGiven =
                                    revealedGuessableAnswers
                                        |> List.map Common.countHints
                                        |> List.foldl (+) 0

                                skeletonViewport =
                                    { scene = viewport.scene
                                    , viewport =
                                        { x = viewport.viewport.x
                                        , y = viewport.viewport.y
                                        , width = viewport.viewport.width
                                        , height = viewport.viewport.height - infoHeight
                                        }
                                    }

                                hintsNeededEmojiAsciiCode : Int -> Int
                                hintsNeededEmojiAsciiCode hintsNeeded =
                                    if hintsNeeded < 5 then
                                        128578

                                    else if hintsNeeded < 15 then
                                        129300

                                    else if hintsNeeded < 30 then
                                        128552

                                    else
                                        128561
                            in
                            H.div
                                [ HA.style "width" "100%"
                                ]
                            <|
                                [ H.div
                                    ([ HA.style "height" (String.px infoHeight)
                                     , HA.style "margin" "0px 0px 0px"
                                     , HA.style "text-align" "center"
                                     , HA.style "height" "100%"
                                     , HA.style "background-color" Palette.backgroundColor
                                     ]
                                        ++ (if hintsGiven == 0 then
                                                [ HA.style "position" "absolute"
                                                , HA.style "width" "100%"
                                                , HA.style "display" "flex"
                                                , HA.style "flex-direction" "column"
                                                , HA.style "justify-content" "center"
                                                ]

                                            else
                                                []
                                           )
                                    )
                                    [ H.div
                                        [ HA.style "font-weight" "bold"
                                        , HA.style "padding-top" "20px"
                                        ]
                                        [ H.text "Finished" ]
                                    , H.div
                                        [ HA.style "text-align" "center" ]
                                      <|
                                        if hintsGiven == 0 then
                                            [ H.span
                                                [ HA.style "font-weight" "bold"
                                                , HA.style "font-size" "20px"
                                                ]
                                                [ H.text (Char.fromCode 10024 |> String.fromChar) ]
                                            , H.text " No hints needed "
                                            , H.span
                                                [ HA.style "font-weight" "bold"
                                                , HA.style "font-size" "20px"
                                                ]
                                                [ H.text (Char.fromCode 128526 |> String.fromChar)
                                                , H.text (Char.fromCode 10024 |> String.fromChar)
                                                ]
                                            ]

                                        else
                                            [ H.span
                                                [ HA.style "color" "red"
                                                , HA.style "font-weight" "semi-bold"
                                                ]
                                                [ H.text (String.fromInt hintsGiven)
                                                ]
                                            , H.span
                                                [ HA.style "font-style" "normal" ]
                                                [ H.text <|
                                                    String.maybePluralize hintsGiven " hint"
                                                        ++ " used "
                                                ]
                                            , H.span
                                                [ HA.style "font-weight" "bold"
                                                , HA.style "font-size" "20px"
                                                ]
                                                [ H.text (Char.fromCode (hintsNeededEmojiAsciiCode hintsGiven) |> String.fromChar) ]
                                            ]
                                    ]
                                , if hintsGiven > 0 then
                                    HumanSkeleton.view skeletonViewport
                                        { bones = guessables
                                        , originalDimensions = HumanSkeleton.dimensions
                                        , hovered = hovered
                                        , showRevealedHintedOnly = True
                                        , config = humanSkeletonConfig
                                        , maybeFocused = Nothing
                                        }

                                  else
                                    H.nothing
                                , viewQuitPlayTrigger viewport
                                ]

                        RunningPhase ->
                            H.div
                                [ HA.style "width" "100%"
                                , HA.style "height" "100%"
                                ]
                                [ HumanSkeleton.view viewport
                                    { bones = guessables
                                    , originalDimensions = HumanSkeleton.dimensions
                                    , hovered = Nothing
                                    , showRevealedHintedOnly = False
                                    , config = humanSkeletonConfig
                                    , maybeFocused =
                                        case maybeCurrentGuess of
                                            Nothing ->
                                                Nothing

                                            Just _ ->
                                                case maybeActiveGuessable of
                                                    Nothing ->
                                                        Nothing

                                                    Just item ->
                                                        Just item.bodypart
                                    }
                                , viewQuitPlayTrigger viewport
                                , case maybeCurrentGuess of
                                    Nothing ->
                                        H.nothing

                                    Just currentGuess ->
                                        case maybeActiveGuessable of
                                            Nothing ->
                                                H.nothing

                                            Just activeGuessable ->
                                                viewGuessInput viewport activeGuessable currentGuessableAnswer currentGuess (Maybe.isJust maybeWordResetAt)

                                {--, H.div
                                    [ HA.style "position" "absolute"
                                    , HA.style "bottom" "5px"
                                    , HA.style "left" "5px"
                                    ]
                                    [ viewDimensions viewport ]
                                    --}
                                ]



-- DEBUG


viewVerticalLine : Html Msg
viewVerticalLine =
    H.div
        [ HA.id "vertical-line"
        , HA.style "width" "100%"
        , HA.style "height" "100%"
        , HA.style "position" "absolute"
        ]
        []


viewDimensions : Dom.Viewport -> Html Msg
viewDimensions { viewport } =
    H.text <|
        "w * h = "
            ++ String.fromInt (floor viewport.width)
            ++ " * "
            ++ String.fromInt (floor viewport.height)



{--H.input
                    [ HA.id "guess-hints"
                    , HA.style "position" "absolute"
                    , HA.autocomplete False
                    , HA.spellcheck False
                    , HA.value <|
                        String.spaceover
                            (String.length currentGuess)
                            (List.map
                                (\gc ->
                                    case gc of
                                        HintedChar c ->
                                            c

                                        _ ->
                                            String.space
                                )
                                guessableAnswer
                                |> String.fromList
                            )
                    , HA.style "outline" "0"
                    , HA.style "border" "0"
                    , HA.style "color" hintColor
                    , HA.style "background-color" "rgba(0,0,0,0.0)"
                    , HA.style "padding" (String.px verticalPadding ++ " " ++ String.px horizontalPadding)
                    , HA.style "font-size" (String.px fontSize)
                    , HA.class "font-mono"
                    ]
                    []
                    --}
